import React, { Component } from "react"
import { TabView, TabPanel } from "primereact/tabview"
import { Switch, Route } from "react-router-dom"
import { Dropdown } from "primereact/dropdown"
import { Checkbox } from "primereact/checkbox"
import { DataGrid } from "../DataGrid"
import { Upload } from "../Forms/Upload"
import { Holdings } from "../Holdings"
import { Logs } from "../Logs"
import { HoldingsButtons } from "./HoldingsButtons"
import * as HOLDINGS from "../../constants/holdings"

class HoldingsTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      update: false,
      list: JSON.parse(localStorage.getItem("user")).group_children,
      libraries:
        props.user && props.user?.group_children.length > 0
          ? JSON.parse(localStorage.getItem("user")).group_children.map(
            (i) => i.Libraries
          )
          : [],
      selected: props.user && props.user?.group_children.length > 0 ? 1 : 0,
      loading: false,
    }
    this.handleOnTabChange = this.handleOnTabChange.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleOnLibraryChange = this.handleOnLibraryChange.bind(this)
  }

  componentDidMount() {
    const { match } = this.props
    let url
    if (match.url.includes(":tab")) url = match.url.replace(":tab", "0")
    else url = match.url
    this.props.history.push(url)
  }

  handleOnLibraryChange(e) {
    const selectedLibraries = [...this.state.libraries]
    if (e.checked) selectedLibraries.push(e.value)
    else selectedLibraries.splice(selectedLibraries.indexOf(e.value), 1)
    this.setState({ libraries: selectedLibraries })
  }

  handleOnTabChange(event) {
    this.setState({ activeIndex: event.index })
    this.props.history.push(`/home/holdings/self/${event.index}`)
  }

  handleUpdate() {
    this.setState({
      update: true,
    })
    setTimeout(() => {
      this.setState({
        update: false,
      })
    }, 250)
  }

  setInputValue(event) {
    const { id } = event.target
    const value = event.value || event.target.value
    const { list } = this.state

    if (id === "tipo_de_carga" && value === 1) {
      this.setState({
        libraries: list.map((i) => i.Libraries),
      })
    } else if (id === "tipo_de_carga" && (value === 0 || value !== 1)) {
      this.setState({
        libraries: [],
      })
    }
    this.setState({
      selected: value,
    })
  }

  render() {
    const { match, history, group, user, parent } = this.props
    const { tipo_fondo_consorcio } = (user && user.tipo_fondo_consorcio) || ""
    const id = group || match.params.id
    const { tab } = match.params
    const { list, libraries } = this.state
    const isParent = user && user.is_parent
    return (
      <div>
        <h2>Gestión de Fondos</h2>
        <TabView activeIndex={+tab} onTabChange={(e) => this.handleOnTabChange(e)}>
          <TabPanel header="Subir Ficheros" leftIcon="pi pi-upload">
            <h3>Publicar desde fichero</h3>
            <label>Tipo de Carga</label>

            {isParent && (
              <Dropdown
                style={{ width: "40%", display: "block" }}
                disabled={!isParent}
                options={HOLDINGS.HOLDINGS_VALUES.TEMPLATE_CONSORCIUM.map(
                  (i, k) => ({
                    label: i,
                    value: k,
                  })
                )}
                id="tipo_de_carga"
                value={this.state.selected}
                placeholder="Select"
                onChange={(e) => this.setInputValue(e)}
              />
            )}

            {(isParent && user)
              ? list.map((i, index) => (
                <div className="checkbox-list" key={index}>
                  <hr />
                  <Checkbox
                    disabled={libraries.length === user.group_children.length}
                    value={i.Libraries}
                    onChange={this.handleOnLibraryChange}
                    checked={this.state.libraries.includes(i.Libraries)}
                  />
                  <label>{i.Libraries}</label>
                </div>
              ))
              : null}

            <div style={{ width: "100%", display: "block", "margin-top": "20px" }}>
              <Upload user={user} libraries={libraries} code={id} type="text/csv" />
            </div>

            <p>
              <hr />
              El sistema acepta ficheros CSV y XLSX, antes de cargar el fichero
              asegúrese de que tiene rellenado todos los campos. Si tiene cualquier
              problema o duda envíe el fichero en excel a cncs@orex.es lo
              verificaremos y nos pondremos en contacto con usted.
            </p>

            <Logs group={group} consorcio={tipo_fondo_consorcio} />
          </TabPanel>
          {/* Can i dedup */}

          <TabPanel header="Añadir Fondo Suelto" leftIcon="pi pi-plus">
            <h3>Añadir fondo suelto</h3>
            <Holdings
              parent={parent}
              user={user}
              edit={false}
              insert
              group={id}
              history={history}
            />
          </TabPanel>

          <TabPanel
            id="pending"
            leftIcon="pi pi-list"
            header="Pendientes de Revisión"
          >
            <h3>
              Fondos de revistas pendientes de revisión y de publicación en el OPAC
            </h3>

            <Switch>
              <Route exact path="/home/holdings/self/:tab">
                <DataGrid
                  type="holdings-pending"
                  path="holdings"
                  group={id}
                  parent={id}
                  isParent={isParent}
                  history={history}
                  update={this.state.update}
                />
                <HoldingsButtons
                  handleUpdate={this.handleUpdate}
                  live={false}
                  group={id}
                />
              </Route>

              <Route
                exact
                path="/home/holdings/self/:tab/:id/:hold/:type/:live"
                render={(routeProps) => (
                  <Holdings
                    history={history}
                    {...routeProps}
                    parent={false}
                    edit
                    insert={false}
                    live={match.params.live}
                  />
                )}
              />
            </Switch>
          </TabPanel>
          <TabPanel id="published" leftIcon="pi pi-eye" header="Revistas Publicadas">
            <h3>Fondos de revistas publicadas en el OPAC</h3>
            <Switch>
              <Route exact path="/home/holdings/self/:tab">
                <DataGrid
                  type="holdings-loaded"
                  path="holdings"
                  group={id}
                  parent={id}
                  isParent={isParent}
                  history={history}
                  update={this.state.update}
                />
                <HoldingsButtons handleUpdate={this.handleUpdate} live group={id} />
              </Route>

              <Route
                exact
                path="/home/holdings/self/:tab/:id/:hold/:type/:live"
                render={(routeProps) => (
                  <Holdings
                    history={history}
                    {...routeProps}
                    parent={false}
                    isParent={isParent}
                    edit
                    insert={false}
                    live={match.params.live}
                  />
                )}
              />
            </Switch>
          </TabPanel>
        </TabView>
      </div>
    )
  }
}
export default HoldingsTemplate

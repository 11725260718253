import React, { Component } from "react"
import { Messages } from "primereact/messages"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { connect } from "react-redux"
import { InputMask } from "primereact/inputmask"
import { InputSwitch } from "primereact/inputswitch"
import validator from "validator"

import FormValidator from "../Utils/FormValidator"
import { AjaxApiFetch } from "../Utils/ajaxApiFetch"
import * as HOLDINGS from "../constants/holdings"
import { locale } from "../constants/language.config"
import { find, includes } from "lodash"

const mapStateToProps = (state) => ({
  state,
})

/*
 Review defaults
*/

const defaultValues = {
  tipo_de_carga: 0,
  grupo: "",
  issn: "",
  tipo_fondo: "",
  titulo: "",
  fondo: "",
  embargo: "",
  "Paquete-e": "",
  id_inc: "",
  solicit: "",
  id_inc_padre: "1",
}

const order = {
  issn: "",
  biblionumber: "",
  titulo: "",
  barcode: "",
  tipo_fondo: "",
  "Paquete-e": "",
  embargo: "",
  fondo: "",
}
const user = JSON.parse(localStorage.getItem("user"))

export class Holdings extends Component {
  constructor(props) {
    super(props)
    const form = null
    this.state = {
      user,
      form,
      checked: false,
      fields: HOLDINGS.HOLDINGS_VALUES.FORM,
      libraries:
        user && user?.group_children.length > 0
          ? user.group_children.map((i) => i.Libraries)
          : [],
      template: user?.is_parent ? user?.tipo_fondo_consorcio : null,
      list: user?.group_children,
      message: { header: "", message: "" },
      type: user && user.group_children?.length > 0 ? 1 : 0,
      valid: null,
      holdingType: user?.tipo_fondo_consorcio || null,
    }
    this.onLibraryChange = this.onLibraryChange.bind(this)
  }

  componentDidMount() {
    const { insert } = this.props
    const user = JSON.parse(localStorage.getItem("user"))

    this.setState({
      user,
      edit: true,
      holdings: true,
      publish: false,
      insert,
      type: user && user.group_children?.length > 0 ? 1 : 0,
      list: user.is_parent ? user.group_children : [],
      libraries:
        user && user?.group_children.length > 0
          ? user.group_children.map((i) => i.Libraries)
          : [],
      holdingType: user.tipo_fondo_consorcio || null,
      form: this.valuesForForm(
        {
          ...defaultValues,
          tipo_de_carga: user && user.group_children?.length > 0 ? 1 : 0,
          tipo_fondo: user.tipo_fondo_consorcio || null,
        },
        HOLDINGS.HOLDINGS_VALUES.FORM
      ),
      template: user.is_parent ? user.tipo_fondo_consorcio : [],
    })
    if (!this.props.match) return

    const { id, hold, type } = this.props.match.params
    const query = hold
    const group = id

    AjaxApiFetch({
      path: "holdings-edit",
      group: `/?id=${query}&type=${type}&group=${group}&parent=${user.is_parent}`,
    }).then((data) => {
      const ordered = type === "barcode" ? { ...order, ...data.values } : data.values
      const formValues = this.valuesForForm(ordered, HOLDINGS.HOLDINGS_VALUES.FORM)
      const holdingType = this.getHoldingsType(formValues)
      this.setState({
        holdingType,
        form: formValues,
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { form } = this.props
    if (prevProps.form && form && prevProps.form.issn !== form.issn) {
      this.updateState(
        Object.keys(form).map((i) => {
          return { id: i, value: form[i] ? form[i] : "", label: i }
        })
      )
    }
  }

  getHoldingsType(form) {
    const { template } = this.state
    const type = form.find(({ id }) => id === "tipo_fondo")
    return type.value || template
  }

  validateForm() {
    const { form, fields, checked } = this.state
    const rules = Object.values(fields)
    const validationRulesRemaped = rules.map((i) => {
      //
      // Could be placed as options @ holdings
      //
      if (i.id === "issn" && checked) {
        i.options = 13
        i.method = validator.isISBN
      }
      return i
    })
    const formValidator = new FormValidator(validationRulesRemaped)
    const validation = formValidator.validate(
      form.reduce((a, i) => {
        a[i.id] = i.value
        return a
      }, {})
    )
    const updatedState = { ...fields }
    for (const k in validation) {
      for (const m in validation[k]) {
        updatedState[k][m] = validation[k][m]
      }
    }
    const isValid =
      Object.values(updatedState).filter((i) => i.isInvalid).length <= 0
    this.setState({
      fields: updatedState,
      holdingType: this.getHoldingsType(form),
      form: form.map((item) => {
        const key = updatedState[item.id]
        if (key) {
          item.isInvalid = key.isInvalid
        }
        return item
      }),
      valid: isValid,
    })

    return isValid
  }

  setInputValue(event) {
    const { id } = event.target
    const value = event.value || event.target.value
    const { form, list, user, holdingType } = this.state
    const edit = form.map((i) => {
      const item = { ...i }
      if (item.id.toLowerCase() === id.toLowerCase()) {
        item.value = item.id === "issn" ? value.toUpperCase() : value
      }
      // if (item.id === "tipo_fondo") {
      //   item.value = holdingType
      // }
      return item
    })

    if (id === "tipo_de_carga" && value === 1) {
      this.setState({
        libraries: list.map((i) => i.Libraries),
        type: value,
        holdingType: user.tipo_fondo_consorcio,
      })
    } else if (id === "tipo_de_carga" && (value === 0 || value !== 1)) {
      this.setState({
        libraries: [],
        type: value,
        holdingType: value > 1 ? user.tipo_fondo_consorcio : "",
      })
    }

    if (id === "tipo_fondo") {
      this.setState({
        holdingType: value,
        form: edit,
      })
    }
    this.setState({
      form: edit,
    })

    // this.validateForm(this.state.form)
  }

  updateState(data) {
    const issnRow = data.filter((i) => i.id === "issn").pop()
    const hasIssn = issnRow.value !== ""
    this.setState({
      form: data,
      hasIssn,
    })
  }

  valuesForForm(form, fields) {
    if (!form) return false

    if (Object.values(form) <= 0) return

    return Object.keys(form).reduce((total, i, k) => {
      const filteredKeys = Object.values(fields)
        .filter((item) => item.id === i)
        .pop()
      if (filteredKeys) {
        filteredKeys.value = form[i]
        total.push(filteredKeys)
      }
      return total
    }, [])
  }

  renderInputForm(form, fields) {
    const { insert, group } = this.props
    const { list, checked } = this.state

    const isChild = list && list.filter((i) => i.Libraries === group).length > 0
    if (!form) return false

    const inputForm = insert
      ? form.filter((i) => i.id !== "barcode" && i.id !== "biblionumber")
      : form
    const biblionumber = form.find(({ id }) => id === "biblionumber")
    const hasBiblionumber = !!biblionumber

    return inputForm && inputForm.length > 0
      ? inputForm.map((i, k) => {
        return i.id !== "tipo_fondo" && i.id !== "tipo_de_carga" ? (
          <div
            className="input-data-wrapper-holdings"
            style={{
              display: i.visible ? "inline-block" : "none",
              width: i.id === "titulo" || i.id === "Paquete-e" ? "50%" : "25%",
            }}
          >
            <label htmlFor={i}>
              <span className="required">{i.required ? "* " : ""}</span>
              <span>{i.label}</span>
            </label>

            {i.id !== "issn" && (
              <InputText
                style={{
                  borderColor:
                    fields[i.id] && fields[i.id].isInvalid ? "red" : "gray",
                  width: i.id === "titulo" || i.id === "Paquete-e" ? "95%" : "90%",
                }}
                id={i.id}
                disabled={i.id === "barcode" || i.id === "biblionumber"}
                value={i.value}
                onChange={(e) => this.setInputValue(e)}
                onBlur={(e) => this.setInputValue(e)}
              />
            )}

            {i.id === "issn" && (
              <>
                <label>issn</label>
                <InputSwitch
                  checked={this.state.checked}
                  onChange={(e) => this.setState({ checked: e.value })}
                />
                <label>isbn</label>
                {!checked ? (
                  <InputMask
                    id={i.id}
                    style={{
                      borderColor:
                        fields[i.id] && fields[i.id].isInvalid ? "red" : "gray",
                      width:
                        i.id === "titulo" || i.id === "Paquete-e" ? "95%" : "90%",
                    }}
                    mask="****-****"
                    onChange={(e) => this.setInputValue(e)}
                    value={i.value}
                  />
                ) : (
                  <span>
                    <InputMask
                      id={i.id}
                      style={{
                        borderColor:
                          fields[i.id] && fields[i.id].isInvalid ? "red" : "gray",
                        width:
                          i.id === "titulo" || i.id === "Paquete-e"
                            ? "95%"
                            : "90%",
                      }}
                      mask="999-9-999-99999-9"
                      onChange={(e) => this.setInputValue(e)}
                      value={i.value}
                    />
                  </span>
                )}
              </>
            )}
          </div>
        ) : (
          <div className={`input-data-wrapper-holdings ${i.id}`}>
            {i.id !== "tipo_de_carga" && (
              <label htmlFor={i.id}>
                <span className="required">{i.required ? "* " : ""}</span>
                <span>{i.label}</span>
              </label>
            )}

            {!isChild && i.id === "tipo_de_carga" && (
              <div>
                <label htmlFor={i.id}>
                  <span>{i.label}</span>
                </label>

                <Dropdown
                  // disabled={list && list.length <= 0}
                  options={this.valuesForSelect(i)}
                  id={i.id}
                  value={this.state.type}
                  placeholder="Seleccione un tipo de carga"
                  onChange={(e) => this.setInputValue(e)}
                />
              </div>
            )}

            {i.id === "tipo_fondo" && (
              <Dropdown
                options={this.valuesForSelect(i)}
                id={i.id}
                value={
                  this.state.edit && this.state.insert
                    ? hasBiblionumber
                      ? i.value
                      : this.state.holdingType
                    : i.value
                }
                placeholder="Select"
                onChange={(e) => this.setInputValue(e)}
                style={{
                  borderColor:
                    fields[i.id] && fields[i.id].isInvalid ? "red" : "gray",
                }}
              />
            )}
          </div>
        )
      })
      : "No Data"
  }

  valuesForSelect(value) {
    const { template, user, type } = this.state

    let values =
      type === 0
        ? HOLDINGS.HOLDINGS_VALUES.TEMPLATE
        : [...HOLDINGS.HOLDINGS_VALUES.TEMPLATE, user.tipo_fondo_consorcio]
    if (template && template.length) {
      values =
        type === 0
          ? HOLDINGS.HOLDINGS_VALUES.TEMPLATE
          : [...HOLDINGS.HOLDINGS_VALUES.TEMPLATE, template]
    }
    if (value.id === "tipo_fondo")
      return values.map((i) => ({
        label: i,
        value: i,
        disabled: type === 1 || type === 2,
      }))
    return find(user.group_children, { Libraries: user.firstname }) ?
      [{
        label: HOLDINGS.HOLDINGS_VALUES.TEMPLATE_CONSORCIUM[0],
        value: 0,
      }]
      : HOLDINGS.HOLDINGS_VALUES.TEMPLATE_CONSORCIUM.map((i, k) => ({
        label: i,
        value: k,
      }))
  }

  printMessage(severity, status, detail) {
    this.messages.show({
      severity,
      summary: status,
      closable: false,
      detail,
    })
  }

  openMessage() {
    const { form, holdingType, template } = this.state
    const isValid = this.validateForm()

    if (!isValid) {
      this.printMessage("error", `${locale["Fill all fields"]}`)
      return
    }

    if (isValid && holdingType === template) {
      this.setState((prevState) => {
        return {
          ...prevState,
          visible: true,
          valid: true,
          message: {
            header: "Tipo de fondo EJNL",
            message:
              "Tipo de fondo EJNL-%' es un fondo consorciado y se cargará en todas las bibliotecas de la red",
            onclick: () => {
              this.saveForm()
            },
          },
        }
      })
    } else if (isValid && (holdingType === "JNL" || holdingType === "EJNL")) {
      this.saveForm()
    } else {
      this.setState({
        valid: false,
      })
    }
  }

  saveForm() {
    const { id } = this.props.match?.params || { id: null }

    const { form, libraries, hasIssn, user, valid } = this.state
    const { insert, edit, group } = this.props || false
    const isParent = id === user.userid
    const data = { form, libraries }
    const barcode = form.filter((i) => i.id === "barcode").pop()
    const issn = form.filter((i) => i.id === "issn").pop()
    const id_inc = form.filter((i) => i.id === "id_inc").pop()
    const biblionumber = form.filter((i) => i.id === "biblionumber").pop()

    const [parent] = user.group_id
    const type = user.tipo_fondo_consorcio
    const parentName = parent?.nombre_padre || null
    this.setState({
      visible: false,
      message: {},
    })
    AjaxApiFetch({
      path: insert ? "insert-holdings-data" : "update-holdings-data",
      group: `/?edit=${edit}&insert=${insert}${insert
        ? `&group=${group}&parent=${isParent}&parentName=${group}`
        : barcode
          ? `&barcode=${barcode.value}&biblionumber=${biblionumber.value}&parent=${isParent}&type=${type}&parentName=${parentName}`
          : hasIssn
            ? `&issn=${issn.value}`
            : `&id_inc=${id_inc.value}`
        }`,
      method: "POST",
      data,
    }).then((data) => {
      if (data.error || data.code) {
        this.printMessage("error", `${locale["Failed for center"]}`)
      } else {
        this.printMessage("success", `${locale["Success holding loaded"]}`)

        this.setState({
          valid: false,
          form: insert
            ? this.valuesForForm(defaultValues, HOLDINGS.HOLDINGS_VALUES.FORM)
            : form,
        })
      }
    })
  }

  onLibraryChange(e) {
    const selectedLibraries = [...this.state.libraries]
    if (e.checked) selectedLibraries.push(e.value)
    else selectedLibraries.splice(selectedLibraries.indexOf(e.value), 1)
    this.setState({ libraries: selectedLibraries })
  }

  pushBack(e) {
    const { back } = this.props
    this.setState({
      form: [],
    })
    back(e)
  }

  render() {
    const { form, fields, list, message, holdingType, template } = this.state
    const { insert, user, parent, history } = this.props

    return (
      <>
        <Button
          icon="pi pi-arrow-left"
          label="Volver"
          className="right-float p-button-rounded"
          onClick={() => history.goBack()}
        />
        {this.renderInputForm(form, fields)}

        {insert && parent && user
          ? list.map((i) => (
            <div className="checkbox-list">
              <hr />
              <Checkbox
                disabled={!parent}
                value={i.Libraries}
                onChange={this.onLibraryChange}
                checked={this.state.libraries.includes(i.Libraries)}
              />
              <label>{i.Libraries}</label>
            </div>
          ))
          : ""}
        <hr />
        <Button
          icon="pi pi-save"
          label="Guardar"
          className="p-button-rounded p-button-success"
          onClick={(e) => this.openMessage(e)}
        />

        {message && holdingType === template && (
          <Dialog
            header={message.header}
            visible={this.state.visible}
            style={{ width: "50vw" }}
            modal
            onHide={() => this.setState({ visible: false })}
          >
            <p>{message.message}</p>

            <Button
              icon="pi pi-trash"
              label="Cancelar"
              className="p-button-rounded cancel"
              onClick={() => this.setState({ visible: false })}
            />

            <Button
              icon="pi pi-trash"
              label="Confirmar"
              className="p-button-rounded remove"
              onClick={message.onclick}
            />
          </Dialog>
        )}

        <Messages ref={(el) => (this.messages = el)} />
        <hr />
      </>
    )
  }
}

export default connect(mapStateToProps)(Holdings)

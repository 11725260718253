import React, { Component } from "react"
import { Button } from "primereact/button"
import { ProgressBar } from "primereact/progressbar"
import axios from "axios"
import { Messages } from "primereact/messages"
import { ProgressSpinner } from "primereact/progressspinner"
import { InputSwitch } from "primereact/inputswitch"

import { server } from "../../config/backend"
import { locale } from "../../constants/language.config"

const UploadFileErrors = ({ groups, issn }) => {
  const issnRowTemplate = (issn, index) =>
    `Error en fila ${index}: El código del issn ${issn} es erroneo, debe de ser xxxx-xxxx (cuatro valores alfanuméricos, guion, cuatro valores alfanuméricos)`
  return (
    <>
      <h2>Errors</h2>
      {issn.values.length > 0 && (
        <div className="holdings-upload-errors">
          <h6>
            {" "}
            {issn.message.replace(
              "{{lines}}",
              issn.values.map((value) => `${value.line}`)
            )}
          </h6>
          {issn.values.length > 0 &&
            issn.values.map((value) => (
              <p> {issnRowTemplate(value.issn, value.line)} </p>
            ))}
          <hr />
        </div>
      )}

      {groups.group.length > 0 && (
        <div className="holdings-upload-errors">
          <h6>
            {" "}
            {groups.message.replace(
              "{{lines}}",
              groups.group.map((value) => `${value.line}`)
            )}
          </h6>
          <div className="holdings-upload-errors-groups">
            {groups.group.map((value) => (
              <span>
                Grupo - {value.group} Linea - {value.line},{" "}
              </span>
            ))}
          </div>
          <hr />
        </div>
      )}
    </>
  )
}

export class Upload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageURL: "",
      type: null,
      progress: 0,
      message: { header: "", message: "" },
      loading: false,
      duplicates: true,
    }

    this.handleUploadFile = this.handleUploadFile.bind(this)
  }

  handleUploadFile(ev) {
    ev.preventDefault()
    const { code, type, libraries, user } = this.props
    const { duplicates } = this.state

    const isBlacklist = code === "blacklist"
    const consorcium =
      !isBlacklist &&
      user &&
      user.tipo_fondo_consorcio &&
      (user.tipo_fondo_consorcio.tipo_fondo_consorcio || "")
    const date = new Date()
    const data = new FormData()
    const file = this.uploadInput.files[0]

    if (!code) {
      this.setState({
        loading: false,
      })
      this.printMessage("error", `${locale["Failed to upload Logo Code"]}`)

      return false
    }

    if (!file) {
      this.printMessage("error", `${locale["Select file"]}`)
      return
    }
    this.setState({
      loading: true,
    })
    if (!isBlacklist) {
      data.append("file", file)
      data.append("filename", file.name)
      data.append("library", code)
      data.append("date", date)
      data.append("libraries", libraries)
      data.append("duplicates", duplicates)
    } else {
      data.append("file", file)
      data.append("filename", file.name)
      data.append("date", date)
    }
    const output = document.getElementById("output")
    const endpoint = isBlacklist
      ? `${server.protocol}://${server.host}/upload-blacklist`
      : `${server.protocol}://${server.host}/upload?library=${code}&consorcium=${consorcium}`

    const config = {
      headers: {
        Authorization: `Basic ${new Buffer(
          `${process.env.REACT_APP_BASIC_AUTH_USER}:${process.env.REACT_APP_BASIC_AUTH_PASS}`
        ).toString("base64")}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        this.setState({
          progress: percentCompleted,
        })
      },
    }

    axios
      .post(endpoint, data, config)
      .then((res) => {
        const progressbar = document.querySelector('[role="progressbar"]')
        const form = document.getElementById("upload-form")
        const logoButton = document.getElementById("add_logo")

        output.className = "container"

        output.innerHTML =
          type === "image"
            ? `<img src=//${server.host}:${server.port}/${res.data.path} />`
            : ""

        if (res.data && res.data.error) {
          this.printMessage("error", res.data.error)
        } else if (
          typeof res.data.map === "function" &&
          res.data.filter((i) => i.value === "failed").length > 0
        ) {
          this.printMessage("error", `${locale["issn already exists"]}`)
        } else if (res.data && res.data.errors) {
          this.setState({
            fileErrors: { ...res.data.errors },
          })
        } else {
          logoButton && logoButton.remove()
          progressbar && progressbar.remove()
          form.remove()
          this.printMessage(
            "success",
            type === "image"
              ? `${locale["Upload Logo successful"]}`
              : `${locale["Upload successful"]}`
          )
        }
        this.setState({
          loading: false,
        })
      })
      .catch((err) => {
        output.className = "container text-danger"
        this.printMessage(
          "error",
          type === "image"
            ? `${locale["Failed to upload Logo"]}`
            : `${locale["Failed to upload"]}`
        )
        this.setState({
          loading: false,
        })
      })
  }

  printMessage(severity, status, detail) {
    this.messages.show({
      severity,
      summary: status,
      closable: false,
      detail,
    })
  }

  render() {
    const { progress, file, fileErrors } = this.state
    const { type } = this.props
    return (
      <div>
        {fileErrors && <UploadFileErrors {...fileErrors} />}

        <span id="output" />

        <form id="upload-form" onSubmit={this.handleUploadFile}>
          {progress > 0 && progress < 100 && (
            <ProgressBar showValue value={progress} />
          )}
          <span style={{ margin: "0 20px" }}>
            Fichero:{" "}
            {file &&
              JSON.stringify(file.value).split(/[\\$]/).pop().replace(/"/g, "")}
          </span>
          <div style={{ float: "left", "margin-right": "20px" }}>
            <input
              className="btn2"
              onChange={(ev) => this.setState({ file: ev.target })}
              ref={(ref) => {
                this.uploadInput = ref
              }}
              type="file"
              id="file"
            />
            <label htmlFor="file" className="btn-2">
              Fichero
            </label>
          </div>

          <Button
            icon="pi pi-upload"
            label="Subir fichero"
            className="p-button-rounded p-button-success"
            onClick={() => this.setState({ fileErrors: null })}
          />
          {type !== "image" && (
            <div style={{ display: "none" }}>
              <span className="duplicates">
                <InputSwitch
                  checked={this.state.duplicates}
                  onChange={(e) =>
                    this.setState({ duplicates: !this.state.duplicates })
                  }
                />
              </span>
              <span className="duplicates-label">Permitir duplicados</span>
            </div>
          )}
          {this.state.loading && (
            <>
              <ProgressSpinner />{" "}
              <span>{`${progress > 0 && progress < 100
                ? locale["Uploading files"]
                : locale["Processing files"]
                }`}</span>
              <br />
              <br />
            </>
          )}
        </form>
        <Messages ref={(el) => (this.messages = el)} />
      </div>
    )
  }
}

import React, { Component } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { RadioButton } from "primereact/radiobutton"
import { AjaxApiFetch } from "../Utils/ajaxApiFetch"
import labels from "../config/language.config"

export class DataGridGroups extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      rows: [],
      columns: [],
      globalFilter: "",
    }

    this.buildTable = this.buildTable.bind(this)
    this.buildSelectTemplate = this.buildSelectTemplate.bind(this)
    this.popUpTemplate = this.popUpTemplate.bind(this)
    this.radioTemplate = this.radioTemplate.bind(this)
    this.buildInputTemplate = this.buildInputTemplate.bind(this)
  }

  buildTable(data) {
    const result = data.children.map((i) => {
      const row = {}

      const parent = data.groups.find((p) => p.id_grupo_padre === i.id_grupo_padre)
      row.Group = i.nombre_hijo
      row.child = i.id_grupo_hijo
      row.parent = i.id_grupo_padre

      const parentNode = parent
      const parentName = parentNode ? parentNode.nombre_padre : ""

      row["Nombre del centro de compra"] = parentName
      row["Fondo Consorciado"] = (parent && parent.tipo_fondo_consorcio) || ""

      if (i.id_grupo_padre !== 0) {
        row["Centro consorciado"] = "SI"
        row["Centro de compra"] = "NO"
      } else {
        row["Centro consorciado"] = "NO"
        row["Centro de compra"] = "SI"
      }
      row.Actualizar = ""
      if (!parent) {
        row["Centro consorciado"] = "SI"
      }
      return row
    })

    // RENAME COLUMN HEADER
    // Group --> Código centro
    // Nombre del centro de compra --> Miembro de la red
    // Fondo consorciado --> Código fondo red
    // Centro consorciado -- Miembro de red
    // Centro de compra --> Responsable de red
    const labelNames = labels.user_management
    const column = Object.keys(result[0])
      .map((i) => {
        return { field: i, header: labelNames[i] }
      })
      .filter((col) => col.field !== "parent" && col.field !== "child")

    const select = data.groups.map((i) => i.nombre_padre)
    this.setState({
      rows: result,
      columns: column,
      select,
      data,
    })
  }

  componentDidMount() {
    const { path } = this.props
    const group = this.props.group ? `?group=${this.props.group}` : ""

    const config = {
      path,
      group,
    }
    AjaxApiFetch(config).then((data) => {
      this.buildTable(data)
    })
  }

  buildLibraryEdit(data) {
    this.setState({
      edit: data,
    })
  }

  getLibraryEdit(event) {
    AjaxApiFetch({
      path: "library-data",
      group: `/?library=${event.target.id}`,
    }).then((data) => {
      this.buildLibraryEdit(data)
    })
  }

  linkTemplate(rowData, column) {
    const selectedValue = rowData["Centro consorciado"]

    return (
      <RadioButton
        value={selectedValue}
        name="consorciado"
        onChange={(e) => this.setState({ value: e.value })}
        checked={selectedValue === "SI"}
      />
    )
  }

  radioInputChange() {
    return false
  }

  radioTemplate(rowData, column) {
    const selectedValue = rowData[column.field]
    return (
      <RadioButton
        disabled
        value={selectedValue}
        name={column.field}
        onChange={(e) => this.radioInputChange(rowData, e.target)}
        checked={selectedValue === "SI"}
      />
    )
  }

  selectValue(rowData, value) {
    const { rows } = this.state
    let index = 0

    const newRow = rows
      .filter((i, k) => {
        if (rowData.Group === i.Group) index = k
        return rowData.Group === i.Group
      })
      .map((i) => {
        i["Centro de compra"] = value.code !== 0 ? "NO" : "SI"
        i["Centro consorciado"] = value.code !== 0 ? "SI" : "NO"
        i["Fondo Consorciado"] = value.code !== 0 ? "" : rowData["Fondo Consorciado"]

        i["Nombre del centro de compra"] = value.code
        i.dirty = true
        return i
      })
    rows[index] = newRow.pop()

    this.setState({
      rows: [...rows],
    })
  }

  inputValue(rowData, value) {
    const { rows } = this.state
    let index = 0
    const newRow = rows
      .filter((i, k) => {
        if (rowData.Group === i.Group) index = k
        return rowData.Group === i.Group
      })
      .map((i) => {
        i["Centro de compra"] = value !== "" ? "SI" : "NO"
        i["Centro consorciado"] = value !== "" ? "NO" : "SI"
        i["Nombre del centro de compra"] =
          value !== "" ? 0 : rowData["Nombre del centro de compra"]

        i["Fondo Consorciado"] = value
        i.dirty = true
        return i
      })
    // const split = rows.filter(i => rowData.Group !== i.Group)
    rows[index] = newRow.pop()

    this.setState({
      rows: [...rows],
    })
  }

  buildSelectTemplate(rowData, column) {
    const { select } = this.state
    const values = select
      .map((i) => ({ name: i, code: i }))
      .concat([{ name: "Ninguno", code: 0 }])
    const selectedValue = values.filter(
      (i) => i.code === rowData["Nombre del centro de compra"]
    )
    const applySelectedValue =
      selectedValue.length > 0 ? selectedValue[0] : { name: "Ninguno", code: 0 }
    return (
      <Dropdown
        optionLabel="name"
        value={applySelectedValue}
        options={values}
        onChange={(e) => this.selectValue(rowData, e.value)}
        placeholder="Seleccionar"
      />
    )
  }

  saveData(rowData) {
    const { data } = this.state
    const parent = data.groups
      .filter((i) => i.nombre_padre === rowData["Nombre del centro de compra"])
      .pop()
    AjaxApiFetch({
      path: "update-groups-data",
      method: "POST",
      data: rowData,
      group: `?parent=${parent ? parent.id_grupo_padre : 0}&child=${rowData.child}`,
    }).then((data) => {
      // console.log(data)
    })
  }

  buildInputTemplate(rowData, column) {
    const disabled = false // rowData['Centro de compra'] === 'NO'
    return disabled ? (
      ""
    ) : (
      <InputText
        id={rowData.Group}
        value={rowData["Fondo Consorciado"]}
        onChange={(e) => {
          this.inputValue(rowData, e.target.value)
        }}
      />
    )
  }

  popUpTemplate(rowData, column) {
    return (
      <Button
        icon="pi pi-check-circle"
        label="Actualizar"
        className={`${rowData.dirty ? "dirty" : ""} right-float p-button-rounded`}
        onClick={(e) => this.saveData(rowData, e)}
      />
    )
  }

  buildTemplates(field) {
    switch (field.field) {
      case "Centro consorciado":
        return this.radioTemplate
      case "Centro de compra":
        return this.radioTemplate
      case "Actualizar":
        return this.popUpTemplate
      case "Nombre del centro de compra":
        return this.buildSelectTemplate
      case "Fondo Consorciado":
        return this.buildInputTemplate
      default:
    }
  }

  render() {
    const dynamicColumns = this.state.columns
      .filter((col) => col.field !== "branchname")
      .map((col, i) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          filter={false}
          sortable
          body={this.buildTemplates(col)}
        />
      ))

    const header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }} />
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder="Global Search"
          size="50"
        />
      </div>
    )

    return (
      <div>
        <DataTable
          ref={(el) => (this.dt = el)}
          value={this.state.rows}
          paginator
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage="Sin Resultados"
          rows={50}
          rowsPerPageOptions={[50, 100]}
        >
          {dynamicColumns}
        </DataTable>
      </div>
    )
  }
}

import React, { Component } from "react"
import {
  Alert,
  Container,
  FormGroup,
  Row,
  Col,
  FormControl,
  FormLabel,
} from "react-bootstrap"
import { Button } from "primereact/button"
import { connect } from "react-redux"
import { loginUserAction } from "../actions/authenticationActions"
import { server } from "../config/backend"
import labels from "../config/language.config"

class Login extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.signIn = this.signIn.bind(this)
    this.messageLog = this.messageLog.bind(this)

    this.username = React.createRef()
    this.password = React.createRef()
    this.state = {
      user: {},
    }
  }

  validateForm() {
    return true
  }

  signIn(data) {
    const { history, loginUserAction, location } = this.props
    const { user, central } = data
    const { state } = location || {}

    let path = ""
    if (state && state.prevlocation) {
      path = state.prevlocation.pathname + (state.prevlocation.search || "")
    } else path = "/home"

    if (user) {
      const { borrowernumber, userid, branchcode, error } = user

      this.setState({
        borrowernumber,
        userid,
        error,
        branchcode,
        password: "",
        central,
      })

      localStorage.setItem("user", JSON.stringify(user))
      localStorage.setItem("central", JSON.stringify(central))
    } else {
      this.setState({
        user: { error: data.code },
      })
      return
    }
    loginUserAction(user)

    if (!data.error) history.push(path, { ...user })
  }

  handleSubmit(event) {
    event.preventDefault()
    const login = {
      username: this.username.current.value,
      password: this.password.current.value,
    }
    fetch(`${server.protocol}://${server.host}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(login),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.error) {
          this.setState({
            user: data,
          })
        } else this.signIn(data)
      })
  }

  messageLog() {
    const { user } = this.state
    if (!user.error) return <div />
    if (user.error) {
      return <Alert variant="danger">{user.error}</Alert>
    }
    return <Alert variant="success">Cool</Alert>
  }

  render() {
    return (
      <div className="Login wrapper" style={{ width: "400px", margin: "50px auto" }}>
        <Container>
          <Row>
            <Col xs={6}>
              <img src="logo.png" alt="logo" />
            </Col>
            <Col />
          </Row>
          <Row>
            <Col xs={12}>
              <h2>{labels.login.headline}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="email" bsSize="large">
                  <FormLabel>{labels.login.username}</FormLabel>
                  <FormControl autoFocus ref={this.username} type="username" />
                </FormGroup>
                <FormGroup controlId="password" bsSize="large">
                  <FormLabel>{labels.login.password}</FormLabel>
                  <FormControl
                    ref={this.password}
                    value={this.state.password}
                    onChange={(e) => this.setState({ passord: e.target.value })}
                    type="password"
                  />
                </FormGroup>

                {this.messageLog()}
                <Button
                  icon="pi pi-unlock"
                  label="Validar"
                  className="p-button-rounded"
                  disabled={!this.validateForm()}
                  type="submit"
                />
              </form>
              <hr />
              <ul>
                <li>
                  <a href={`//${process.env.REACT_APP_VUFIND_URL}`}>
                    Volver a web publica
                  </a>
                </li>
                <li>
                  <a
                    href={`//${process.env.REACT_APP_KOHA_URL}/cgi-bin/koha/opac-password-recovery.pl`}
                  >
                    ¿Ha olvidado su contraseña?
                  </a>
                </li>
              </ul>
              <hr />
              <small>{labels.login.message}</small>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({ login: state.login })
export default connect(mapStateToProps, { loginUserAction })(Login)
